<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="1300px"
      :ok-button-props="{ props: { loading: submitting } }"
      title="修改其他项目"
    >
      <template slot="footer">
        <a-button @click="handleCancel">
          取消
        </a-button>
        <a-button type="primary" @click="handleSubmit">
          确定
        </a-button>
      </template>

      <div class="custom-flex custom-justify-between set-background-box">
        <div class="package-name-count">
          <div style="font-weight: bolder">已选项目</div>
          <div style="margin-top: 10px">共{{ tableData.length }}项</div>
        </div>
        <div class="custom-flex" style="background-color: #ff9e1c">
          <a-icon type="pay-circle" style="height: 35px;width: 35px;color: #ffffff;margin: 3px;fontSize:45px" />
          <div style="color:#ffffff;padding-left: 10px;">
            项目金额：
            <div class="set-price-font">{{ fee | formatCurrency }}元</div>
          </div>
        </div>
      </div>

      <item-search @submit="submitSearch" v-show="isShowFirstModal" />

      <a-table
        :columns="firstTableColumns"
        :data-source="tableData"
        :loading="loading"
        :pagination="false"
        row-key="id"
        v-show="isShowFirstModal"
      >
        <span slot="actions" slot-scope="text, record">
          <a-space>
            <a @click="showItemOrderBatchRecordModal(record)">修改</a>
          </a-space>
        </span>
      </a-table>

      <!-- 预览图片 -->
      <preview-image
        :visible.sync="previewVisible"
        :images.sync="previewImages"
      />

      <item-order-batch-record
        v-if="isShowItemOrderBatchRecord"
        :visible.sync="isShowItemOrderBatchRecord"
        :editing-records="editingRecord"
        :record-name="recordName"
        @completed="updateOtherItem"
      />

    </a-modal>
  </div>
</template>

<script>
import ItemSearch from '@/views/orders/tenant/operation_modal/ItemSearch'
import { formatCurrency } from '@/utils/filter'
import { findProjectSummary, updateOtherItem } from '@/api/order'
import ItemOrderBatchRecord from '@/views/orders/tenant/operation_modal/ItemOrderBatchRecord'
import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'DetermineOtherItems',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'task_user' }),
      fee: 2000,
      submitting: false,
      loading: false,
      isShowItemOrderBatchRecord: false,
      editingRecord: [],
      previewVisible: false,
      previewImages: [],
      isShowFirstModal: true,
      distributionUserPhone: '',
      flowerUserPhone: '',
      hallUserPhone: '',
      recordName: '',
      tableData: []
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ItemSearch,
    // eslint-disable-next-line vue/no-unused-components
    ItemOrderBatchRecord,
    PreviewImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    firstTableColumns() {
      return [
        {
          title: '序号',
          dataIndex: 'id',
          width: 100,
          fixed: 'left'
        },
        {
          title: '品名',
          width: 150,
          dataIndex: 'name'
        },
        {
          title: '型号',
          width: 90,
          dataIndex: 'project_type'
        },
        {
          title: '数量',
          width: 100,
          dataIndex: 'project_count'
        },
        {
          title: '项目金额(元)',
          width: 120,
          dataIndex: 'project_fee',
          customRender: formatCurrency
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findProjectSummary(this.id).then((res) => {
        this.tableData = res.data.data
      })
    },
    selectDistributionUser(e) {
      this.distributionUser.map(user => {
        if (user.id === e) {
          this.distributionUserPhone = user.phone_number
        }
      })
    },

    selectFlowerUser(e) {
      this.distributionUser.map(user => {
        if (user.id === e) {
          this.flowerUserPhone = user.phone_number
        }
      })
    },

    selectHallUser(e) {
      this.distributionUser.map(user => {
        if (user.id === e) {
          this.hallUserPhone = user.phone_number
        }
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData(this.query)
    },

    showItemOrderBatchRecordModal(record) {
      this.editingRecord = record.service_order_other_product_items
      this.recordName = record.name
      this.isShowItemOrderBatchRecord = true
    },

    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    handleCancel() {
      this.isShow = false
    },

    handleNext() {
      this.isShowFirstModal = false
      this.isShowSecondModal = true
    },

    handleBack() {
      this.isShowSecondModal = false
      this.isShowFirstModal = true
    },
    updateOtherItem(data) {
      this.editingRecord = data
    },
    handleSubmit() {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const editData = Object.assign({}, values, { items: this.editingRecord })
          updateOtherItem(this.serviceOrderId, editData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }

  }
}
</script>

<style scoped lang="less">
  .set-background-box {
    margin: 10px;
    box-shadow: -1px -1px 10px 5px #EEEFEFFF;
    padding: 10px;

.package-name-count {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
  }

  .set-price-font {
    font-size: 10px;
    font-size: 23px;
  }
</style>
