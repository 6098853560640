import { render, staticRenderFns } from "./UpdateOtherItems.vue?vue&type=template&id=3bec0d83&scoped=true&"
import script from "./UpdateOtherItems.vue?vue&type=script&lang=js&"
export * from "./UpdateOtherItems.vue?vue&type=script&lang=js&"
import style0 from "./UpdateOtherItems.vue?vue&type=style&index=0&id=3bec0d83&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bec0d83",
  null
  
)

export default component.exports